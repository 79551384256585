<template>
  <div class="visitOwnerConfigList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      exportMethod="new"
      :searchUrl="searchUrl"
      :exportUrl="exportUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #headerSlot>
        <v-button text="新增" permission="add"  @click="create"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="计划名称" v-model="searchParams.planName"></v-input>
        <v-select2 v-model="searchParams.communityId" label="所属项目"  v-bind="communityIdConfig"></v-select2>
      </template>
      <template #operateSlot="scope">
        <v-button v-if="scope.row.status === 0" text="编辑" permission="edit" type="text" @click="edit(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 0" text="生成任务" permission="generateTask" type="text" @click="addTask(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 0" text="删除" permission="del" type="text" @click="changeState(scope.row)"></v-button>
        <v-button v-if="scope.row.status === 1" text="查看任务" type="text" @click="lookTask(scope.row)"></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import {
  getListURL,
  exportListURL,
  uploadGuideURL,
  delPlanURL,
  generateTaskURL
} from './api'
import { statusOps, statusMap,visitTypeList,visitTypeMap,isCompleteMap} from './map'
import { communityParams, tenantParams } from 'common/select2Params'


export default {
  name: 'visitPlanConfigList',
  data () {
    return {
      searchUrl: getListURL,
      exportUrl: exportListURL,
      statusOps,
      visitTypeList,
      communityParams,
      tenantParams,
      communityIdConfig: communityParams,
      searchParams: {
        planName: '',
        communityId: undefined,
      },
      headers: [
        {
          prop: 'planName',
          label: '计划名称'
        },
        {
          prop: 'tempName',
          label: '拜访模版'
        },
        {
          prop: 'visitType',
          label: '拜访类型',
          formatter (row) {
            return visitTypeMap[row.tempType]
          }
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'tempName',
          label: '拜访时间',
          formatter (row) {
            return row.startTime + '~' + row.endTime
          }
        },
        {
          prop: 'status',
          label: '状态',
          formatter (row) {
            return statusMap[row.status]
          }
        },
        {
          prop: 'isComplete',
          label: '完成状态',
          formatter (row) {
            return isCompleteMap[row.isComplete]
          }
        }

      ]
    }
  },
  methods: {
    create () {
      this.$router.push({
        name: 'VisitPlanConfigForm'
      })
    },
    edit (row) {
      this.$router.push({
        name: 'VisitPlanConfigForm',
        query: {
          id: row.id
        }
      })
    },
    lookTask (row) {
      this.$router.push({
        name: 'VisitTaskList',
        query: {
          planName: row.planName,
          communityId:row.communityId
        }
      })
    },
    async addTask(row) {
      let msg = '是否确认生成拜访任务'
      let isOk = await this.$confirm(msg)
      let data = {
        id: row.id
      }
      isOk && this.$axios({
        url: generateTaskURL,
        method: 'post',
        data: data
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    },
    async changeState (row) {
      let msg = '是否确认删除'
      let isOk = await this.$confirm(msg)

      let data = {
        id: row.id
      }
      isOk && this.$axios({
        url: delPlanURL,
        method: 'post',
        data: data
      }).then(res => {
        if (res.status === 100) {
          this.$refs.list.searchData()
          this.$message({
            type: 'success',
            message: '操作成功',
            center: true
          })
        }
      })
    }
  }
}
</script>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "visitOwnerConfigList-wrapper" },
    [
      _c("list", {
        ref: "list",
        attrs: {
          exportPermission: "export",
          exportMethod: "new",
          searchUrl: _vm.searchUrl,
          exportUrl: _vm.exportUrl,
          searchParams: _vm.searchParams,
          headers: _vm.headers,
        },
        on: {
          "update:searchParams": function ($event) {
            _vm.searchParams = $event
          },
          "update:search-params": function ($event) {
            _vm.searchParams = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "headerSlot",
            fn: function () {
              return [
                _c("v-button", {
                  attrs: { text: "新增", permission: "add" },
                  on: { click: _vm.create },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c("v-input", {
                  attrs: { label: "计划名称" },
                  model: {
                    value: _vm.searchParams.planName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "planName", $$v)
                    },
                    expression: "searchParams.planName",
                  },
                }),
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目" },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityIdConfig,
                    false
                  )
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "operateSlot",
            fn: function (scope) {
              return [
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: { text: "编辑", permission: "edit", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.edit(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: {
                        text: "生成任务",
                        permission: "generateTask",
                        type: "text",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.addTask(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 0
                  ? _c("v-button", {
                      attrs: { text: "删除", permission: "del", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.changeState(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
                scope.row.status === 1
                  ? _c("v-button", {
                      attrs: { text: "查看任务", type: "text" },
                      on: {
                        click: function ($event) {
                          return _vm.lookTask(scope.row)
                        },
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }